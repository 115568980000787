import $ from "jquery";
import axios from "axios";
import "../utils/array-extensions";

declare var psol: any;

export class Product3dViewer {
  private readonly containerSelector = ".js-product-3d-viewer-container";
  private readonly viewerSelector = ".js-product-3d-viewer";

  private readonly $viewer: JQuery<HTMLElement>;

  constructor() {
    this.$viewer = $(`${this.containerSelector} ${this.viewerSelector}`);

    const modalEl = document.getElementById("3d-viewer-modal");

    if (!modalEl) {
      return;
    }

    modalEl.addEventListener("shown.bs.modal", () => {
      this.init();
    });
  }

  // @ts-ignore
  private init(): void {
    const instance = this;

    // overwrite the browser language with the language chosen by the user in the ATS web portal
    const locale = $("html").attr("lang") ?? "";
    const language = locale.split("-").first();
    psol.core.setLanguage(psol.core.getLanguageByShortIso(language).language);

    psol.core.setApiKey("3c1757e51cf74a2bb400d036478ec3d1");
    psol.core.setUserInfo({
      server_type: "SUPPLIER_EXTERNAL_esc",
      userfirm: this.$viewer.data("customerCompanyName"),
    });
    psol.core.setServiceBaseUrl("https://webapi.partcommunity.com");

    const logNotFoundEndpoint = this.$viewer.data("logNotFoundEndpoint") ?? "";

    // do a reverse lookup with the part number
    psol.core
      .ajaxGetOrPost({
        url: psol.core.getServiceBaseUrl() + "/service/reversemap",
        data: {
          catalog: this.$viewer.data("brandName")?.toLowerCase(),
          part: "" + this.$viewer.data("partNbr"),
          exact: "0",
        },
      })
      .then(function (reverseMapResult: any) {
        const mident = reverseMapResult.mident || "";

        // has mident?
        if (mident.length === 0) {
          instance.logNotFound(logNotFoundEndpoint, "reverseMap");
          return;
        }

        const settings: any = {
          $container: instance.$viewer,
          viewerBackendType: psol.components.WebViewer3D.ViewerBackends.WebGL,
          devicePixelRatio: window.devicePixelRatio,
          radialMenuActions: instance.getRadialMenuActions(),
          favoriteActions: instance.getFavoriteActions(),
          webglViewerSettings: {},
          showProgressDialog: false,
          showLabels: true,
          showLabelsInitially: false,
        };

        const viewer = new psol.components.WebViewer3D(settings);

        viewer
          .show()
          .then(function () {
            instance.loadViewer(viewer, mident, instance);
          })
          .catch(function () {
            instance.logNotFound(logNotFoundEndpoint, "viewer.show");
          });
      });
  }

  private loadViewer(viewer: any, mident: any, instance: any) {
    viewer
      .loadByVarset(null, null, mident, {
        defaultView: true,
      })
      .then(function () {
        // enable download button and set onClick event.
        const $btnDownload = $(".js-product-3d-viewer-download");
        $btnDownload.removeAttr("disabled");

        $btnDownload.on("click", function () {
          const downloadDialog = new psol.components.DownloadDialog({
            mident: mident,
          });

          downloadDialog.show();
        });
      })
      .catch(function () {
        instance.logNotFound(instance.$viewer.data("logNotFoundEndpoint") ?? "", "viewer.loadByVarset");
      });
  }

  private logNotFound(logNotFoundEndpoint: string, source: string) {
    axios.get(`${logNotFoundEndpoint}&source=${source}`).then((_) => {});
  }

  private getRadialMenuActions() {
    return [
      {
        name: "menu_shading",
        subActions: ["actLine", "actShade", "actShadeLine", "actTransparency", "actSAO"],
      },
      {
        name: "menu_rotation",
        subActions: ["actFront", "actBack", "actLeft", "actRight", "actTop", "actBottom", "actIsometric", "actAnimate"],
      },
      {
        name: "menu_vr",
        subActions: [
          "actVR",
          "actAnaglyph",
          "actBluebox",
          "actDreamocHD3",
          "actDreamocXL2",
          "actDreamocXL4",
          "actDreamocMOBILE",
          "actDRV",
          "actFullscreen",
          "actPseudoFullscreen",
          "actHoloLens",
        ],
      },
      {
        name: "menu_special",
        subActions: [
          "actZoomall",
          "actZoomObject",
          "actSaveCameraParams",
          "actCut",
          "actCustomDimensions",
          "actMeasureGrid",
          "actOriginAxes",
          "actMeasure",
          "actExplosion",
          "actLabels",
          "actHotSpots",
          // 'actDrawing', // error, freezes the viewer
          "actCameraBackground",
          "actScreenShot",
        ],
      },
    ];
  }

  private getFavoriteActions() {
    return ["actMeasureGrid", "actLine", "actShade", "actShadeLine", "actPseudoFullscreen", "actTeleport", "actHelp"];
  }
}

// iife: immediately invoked function expression
// ensure that the code is executed only after the DOM is fully loaded, i.e. the CADENAS js file is loaded from <head>
(function () {
  // https://stackoverflow.com/a/55049434
  function delayedInitialization() {
    // setting timeout to 0 pushes the execution of the function to the end of the event loop
    setTimeout(function () {
      new Product3dViewer();
    }, 0);
  }

  if (document.readyState == "complete") {
    delayedInitialization();
  } else {
    document.onreadystatechange = function () {
      if (document.readyState === "complete") {
        delayedInitialization();
      }
    };
  }
})();
